<template>
    <div class="default-timeline-wrapper">
        <div class="default-timeline-progress-container">
            <div class="default-timeline-progress-blur" />
            <div class="default-timeline-progress">
                <div ref="timelineBar" class="default-timeline-progress-bar" />
            </div>
            <div class="timeline-point">
                <div class="timeline-circle" />
            </div>
        </div>
        <div class="timeline-content-container">
            <slot name="content" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    gsap.registerPlugin(ScrollTrigger);
    const timelineBar = ref(null);
    onMounted(() => {
        // gsap.timeline({
        //     scrollTrigger: {
        //         trigger: '.default-timeline-progress',
        //         start: 'top center',
        //         end: 'bottom center',
        //         scrub: true,
        //     },
        // })
        //     .set(timelineBar.value, { height: 0 })
        //     .to(timelineBar.value, {
        //         height: '100%',
        //         ease: 'none',
        //     });
        ScrollTrigger.create({
            trigger: '.default-timeline-progress',
            start: 'top center',
            end: 'bottom center',
            scrub: true,
            onUpdate: (self) => {
                gsap.set(timelineBar.value, { height: `${self.progress * 100}%` });
            },
        });
    });
</script>

<style lang="less" scoped>
    .default-timeline-wrapper {
        z-index: 0;
        width: .convert(335px) [ @vw-mobile];
        margin: 0 auto;
        background: #000;
        position: relative;
        display: flex;
        height: fit-content;

        .default-timeline-progress-container {
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .default-timeline-progress-blur {
                width: 5px;
                height: 200px;
                position: absolute;
                z-index: 0;
                background: rgb(0, 0, 0);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
            }
            .default-timeline-progress {
                z-index: -1;
                width: 3px;
                height: 100%;
                background-color: #fff;
                position: absolute;

                .default-timeline-progress-bar {
                    z-index: -1;
                    display: block;
                    position: absolute;
                    width: 3px;
                    height: 0;
                    background-color: #0032ff;
                    background-repeat: no-repeat;
                }
            }
            .timeline-point {
                position: -webkit-sticky;
                position: sticky;
                align-self: flex-start;
                top: 50vh;
                flex: none;
                .timeline-circle {
                    width: 15px;
                    height: 15px;
                    max-height: 15px;
                    max-width: 15px;
                    min-height: 15px;
                    min-width: 15px;
                    border-radius: 100%;
                    background-color: #fff;
                }
            }
        }
        .timeline-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: .convert(30px) [ @vw-mobile];
        }
    }
</style>
